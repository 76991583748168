<template>
  <div class="UserInfo" v-if="pano" style="pointer-events:none">
    <!--pano:{{pano}}-->
    <template v-if="config.event||config.name">
      <div v-if="pano.isPro&& pano.logo">
        <div class="text-center">
          <img style="max-width:100%" :src="pano.logo.url" @click.prevent="$emit(config.name);doTarget(config.event)">
        </div>
      </div>
      <div v-else-if="!(pano.isPro&&pano.hideAvatar)" class="avatar" style="pointer-events:auto;max-width: 85px;">
        <div class="text-center">
          <a v-if="pano&&pano.user" @click.prevent="$emit(config.name);doTarget(config.event)">
            <Avatar :user="pano.user" :size="50"></Avatar>
          </a>
        </div>
        <div id="nickName" class="text-center text-truncate text-shadow">
          <a v-if="pano&&pano.user" style="color:white" @click.prevent="$emit(config.name);doTarget(config.event)">
            {{ pano.user.nickName }}
          </a>
        </div>
      </div>
    </template>
    <template v-else>
      <div v-if="pano.isPro&& pano.logo">
        <div class="text-center">
          <img style="max-width:100%" :src="pano.logo.url">
        </div>
      </div>
      <div v-else-if="!(pano.isPro&&pano.hideAvatar)" class="avatar" style="pointer-events:auto;max-width: 85px;">
        <template v-if="mp">
          <div class="text-center">
            <a v-if="pano&&pano.user">
              <Avatar :user="pano.user" :size="50"></Avatar>
            </a>
          </div>
          <div id="nickName" class="text-center text-truncate text-shadow">
            <a v-if="pano&&pano.user" style="color:white">
              {{ pano.user.nickName }}
            </a>
          </div>
        </template>
        <template v-else>
          <div class="text-center">
            <router-link v-if="pano&&pano.user" :to="{name:'UserHome',params:{userName:pano.user.userName}}">
              <Avatar :user="pano.user" :size="50"></Avatar>
            </router-link>
          </div>
          <div id="nickName" class="text-center text-truncate text-shadow">
            <router-link v-if="pano&&pano.user" style="color:white" :to="{name:'UserHome',params:{userName:pano.user.userName}}">
              {{ pano.user.nickName }}
            </router-link>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>
<script>
  import Avatar from '../../Avatar'

  export default {
    components: {
      Avatar
    },
    props: {
      config: {
        default() {
          return {}
        }
      },
    },
    data() {
      return {
        mp: null,
      }
    },
    inject: {
      publicData: {
        default: {}
      },
      getFunc: {
      }

    },
    computed: {
      pano() {
        return this.publicData.pano || {}
      },
    },
    created() {
    },
    mounted() {
      this.mp = this.$mp.getMP()
      this.$emit('ready', {
        vm: this,
        events: {
        },
        funcs: {
        }
      })
    },
    destroyed() {
    },
    methods: {
      doTarget(e) {
        if (!e) {
          return
        }
        return this.getFunc(e)()
      },
    },
  }
</script>
<style scoped>
  .UserInfo {
    max-width: 30vw;
    opacity: 0.7;
  }

  .avatar img {
    width: 50px;
    height: 50px;
    /*border: 3px solid #fff;*/
    border-radius: 50%;
  }
</style>
